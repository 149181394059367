import { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { DefaultLayout } from './layouts';
import { publicRoutes } from './routes';
import AppLoading from './core/components/common/app-loading';
import NotFoundPage from './pages/not-fountd';
import EmptyLayout from './layouts/empty';

function App() {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        window.addEventListener('beforeunload', () => setLoading(true));
        window.addEventListener('load', () => setLoading(false));

        return () => {
            window.removeEventListener('beforeunload', () => setLoading(true));
            window.removeEventListener('load', () => setLoading(false));
        };
    }, []);
    return loading ? (
        <AppLoading />
    ) : (
        <BrowserRouter>
            <div className="app">
                <Routes>
                    {publicRoutes.map((route) => {
                        const Page = route.component;
                        switch (route.layout) {
                            case null:
                                return (
                                    <Route key={route.path} path="/" element={<EmptyLayout />}>
                                        <Route path={route.path} element={<Page />}></Route>
                                    </Route>
                                );
                            default:
                                return (
                                    <Route
                                        key={route.path}
                                        path="/"
                                        element={<DefaultLayout isShowNavigation={route.isShowNavigation} />}
                                    >
                                        <Route path={route.path} element={<Page />}></Route>;
                                    </Route>
                                );
                        }
                    })}
                    <Route path="*" element={<NotFoundPage />} />
                </Routes>
            </div>
        </BrowserRouter>
    );
}

export default App;
