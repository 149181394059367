import React, { createContext, useEffect, useMemo, useState } from 'react';

import usePrepareExecuteApi from '~/core/hook/usePrepareExecuteApi';
import { PriceGasDTO } from './dto';

import HeaderService from './service';

const HeaderLogicContext = createContext({} as HeaderLogicContextType);

export interface HeaderLogicContextType {
    priceGas: PriceGasDTO;
    setPriceGas: React.Dispatch<React.SetStateAction<PriceGasDTO>>;
}

function HeaderLogicProvider({ children }: { children: React.ReactNode }) {
    const [priceGas, setPriceGas] = useState<PriceGasDTO>({} as PriceGasDTO);
    const { request: getPriceGasOfMonth } = usePrepareExecuteApi({
        apiWillExecute: HeaderService.getPriceGasOfMonth,
    });

    useEffect(() => {
        const fetchPriceGasOfMonth = async () => {
            const data = await getPriceGasOfMonth();
            setPriceGas(data);
        };
        fetchPriceGasOfMonth();
    }, []);

    const valuesContext: HeaderLogicContextType = useMemo(
        () => ({
            priceGas,
            setPriceGas,
        }),
        [priceGas, setPriceGas],
    );

    return <HeaderLogicContext.Provider value={valuesContext}>{children}</HeaderLogicContext.Provider>;
}

export { HeaderLogicContext, HeaderLogicProvider };
