import React, { memo, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Segmented } from 'antd';
import { Arrow } from '../../../../../core/components/common/arrow';

function CarouselBanner() {
    const [activeSegmented, setActiveSegmented] = useState('0');
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 500,
        autoplaySpeed: 6000,
        cssEase: 'linear',
        pauseOnHover: true,
        nextArrow: <Arrow type={'NEXT'} />,
        prevArrow: <Arrow type={'PREV'} />,
        beforeChange: (current: number, next: number) => setActiveSegmented(String(next)),
    };
    return (
        <div className="flex items-center flex-col shadow-custom-1">
            <div className="relative h-[200px] md:h-[350px] w-full shadow-custom-4">
                <Slider {...settings}>
                    <div className="w-full h-[200px] md:h-[350px]">
                        <img
                            src="https://firebasestorage.googleapis.com/v0/b/gasquynhl.appspot.com/o/banner%2Fbanner-1.jpg?alt=media&token=5f927903-a21e-4fb5-8d3b-86d7f4f42dec"
                            alt=""
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <div className="w-full h-[200px] md:h-[350px]">
                        <img
                            src="https://firebasestorage.googleapis.com/v0/b/gasquynhl.appspot.com/o/banner%2Fbo-bep-gas.jpg?alt=media&token=52e8d2ec-9e0c-4c21-81fd-24d3f67327c1"
                            alt=""
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <div className="w-full h-[200px] md:h-[350px]">
                        <img
                            src="https://firebasestorage.googleapis.com/v0/b/gasquynhl.appspot.com/o/banner%2Fbep-gas-am.jpg?alt=media&token=133717cb-deb4-447c-b4cf-5a2eacfbcad0"
                            alt=""
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <div className="w-full h-[200px] md:h-[350px]">
                        <img
                            src="https://firebasestorage.googleapis.com/v0/b/gasquynhl.appspot.com/o/banner%2Fbep-gas-don.jpg?alt=media&token=726c6e26-d511-4bc7-9ab7-fecaada213c9"
                            alt=""
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <div className="w-full h-[200px] md:h-[350px]">
                        <img
                            src="https://firebasestorage.googleapis.com/v0/b/gasquynhl.appspot.com/o/banner%2Fbanner-2.jpg?alt=media&token=bfbb99f4-da19-42d9-9d7d-4413583aaf5a"
                            alt=""
                            className="w-full h-full object-cover"
                        />
                    </div>
                </Slider>
            </div>
            <div className="h-[50px] w-full nav-segmented hidden md:block">
                <Segmented
                    onChange={(value) => setActiveSegmented(String(value))}
                    value={activeSegmented}
                    options={[
                        {
                            label: (
                                <div className="flex items-center flex-col justify-center h-[50px] text-sm">
                                    <div>Đổi Gas Ngay</div>
                                    <div>Quà Liền Tay</div>
                                </div>
                            ),
                            value: '0',
                        },
                        {
                            label: (
                                <div className="flex items-center flex-col justify-center h-[50px]">
                                    <div>Bộ Bình Gas</div>
                                    <div>Tặng Bếp Gas</div>
                                </div>
                            ),
                            value: '1',
                        },
                        {
                            label: (
                                <div className="flex items-center flex-col justify-center h-[50px]">
                                    <div>Bếp Gas Âm</div>
                                    <div>Giá Cực Sốc</div>
                                </div>
                            ),
                            value: '2',
                        },
                        {
                            label: (
                                <div className="flex items-center flex-col justify-center h-[50px]">
                                    <div>Bếp Gas Đơn</div>
                                    <div>Đồng Giá 300k</div>
                                </div>
                            ),
                            value: '3',
                        },
                        {
                            label: (
                                <div className="flex items-center flex-col justify-center h-[50px]">
                                    <div>Bếp Gas Cũ</div>
                                    <div>Đổi Bếp Mới</div>
                                </div>
                            ),
                            value: '4',
                        },
                    ]}
                />
            </div>
        </div>
    );
}

export default memo(CarouselBanner);
