import { APP_ENV } from '~/core/components/constants';
import configLocal from './local.json';
import configProduction from './production.json';
import configDev from './dev.json';

const getConfig = () => {
    console.log(process.env.NODE_ENV);

    if (process.env.NODE_ENV === APP_ENV.PRODUCTION) {
        return configProduction;
    } else if (process.env.NODE_ENV === APP_ENV.DEV) {
        return configDev;
    } else {
        return configLocal;
    }
};

const ConfigEnv = getConfig();

export { ConfigEnv };
