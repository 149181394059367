import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ProductAttributes, ProductDiscountAttributes } from '../common/dto';
import homeService from '../common/service';
import usePrepareExecuteApi from '~/core/hook/usePrepareExecuteApi';
import { NewsAttributes } from '../news/dto';

const HomeLogicContext = createContext({} as HomeLogicContextType);

export interface HomeLogicContextType {
    gasSafe: ProductAttributes[];
    gasStove: ProductAttributes[];
    accessory: ProductAttributes[];
    electricStove: ProductAttributes[];
    promotion: ProductDiscountAttributes[];
    latestNews: NewsAttributes;
    setLatestNews: React.Dispatch<React.SetStateAction<NewsAttributes>>;
    navigate: ReturnType<typeof useNavigate>;
}

function HomeLogicProvider({ children }: { children: React.ReactNode }) {
    const navigate = useNavigate();
    const [gasSafe, setGasSafe] = useState<ProductAttributes[]>([]);
    const [gasStove, setGasStove] = useState<ProductAttributes[]>([]);
    const [accessory, setAccessory] = useState<ProductAttributes[]>([]);
    const [electricStove, setElectricStove] = useState<ProductAttributes[]>([]);
    const [promotion, setPromotion] = useState<ProductDiscountAttributes[]>([]);
    const [latestNews, setLatestNews] = useState<NewsAttributes>({} as NewsAttributes);
    const { request: getProductForHome } = usePrepareExecuteApi<any, any>({
        apiWillExecute: homeService.getProductForHome,
        failMes: 'Lấy thông tin sản phẩm thất bại!',
    });

    const fetchProductForHome = useCallback(async () => {
        const data = await getProductForHome('SUPER_COMPANY_CF0C92B3DE6B4E99977C53A90BC184AB');

        setGasSafe(data?.products?.gasSafe.rows || []);
        setGasStove(data?.products?.gasStove.rows || []);
        setPromotion(data?.products?.discountWeek || []);
        setElectricStove(data?.products?.electricStove.rows || []);
        setAccessory(data?.products?.accessory.rows || []);
    }, []);

    useEffect(() => {
        fetchProductForHome();
    }, []);

    const valuesContext: HomeLogicContextType = useMemo(
        () => ({
            gasSafe,
            gasStove,
            promotion,
            accessory,
            electricStove,
            latestNews,
            setLatestNews,
            navigate,
        }),
        [gasSafe, gasStove, promotion, accessory, electricStove, latestNews, setLatestNews],
    );

    return <HomeLogicContext.Provider value={valuesContext}>{children}</HomeLogicContext.Provider>;
}

export { HomeLogicContext, HomeLogicProvider };
