import { ArrowIcon } from '../icons/arrow';

type ArrowComponentType = {
    type?: string;
    onClick?: any;
    classes?: string;
};
export const Arrow = (props: ArrowComponentType) => {
    const { type, onClick, classes } = props;
    return (
        <div
            className={`cursor-pointer z-10 absolute top-1/2 ${
                type === 'NEXT' ? 'rotate-180 right-3' : 'left-3'
            } ${classes}`}
            onClick={onClick}
        >
            <div className="w-[30px] h-[30px] bg-[#A75D5D] flex justify-center items-center rounded-full transform shadow-custom-4">
                <ArrowIcon fill="#fff" />
            </div>
        </div>
    );
};
