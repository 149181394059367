export const ShieldIcon = ({ fill = '#000', width = 20, height = 20 }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.3636 3L5 6.27273V11.1818C5 15.7227 8.14182 19.9691 12.3636 21C16.5855 19.9691 19.7273 15.7227 19.7273 11.1818V6.27273L12.3636 3ZM13 16H11V13H8V11H11V8H13V11H16V13H13V16Z"
                fill={fill}
            />
        </svg>
    );
};
