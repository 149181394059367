import ShoppingCartUI from './components/shopping-cart';
import { LogoUI } from './components/logo';
import { BoxSearch } from './components/box-search';
import './style.scss';
import { MenuIcon } from '../../../core/components/icons/menu';
import { memo, useState } from 'react';
import DrawerMenu from './components/drawer-menu';
import HeaderBottom from './components/header-bottom';
import { HeaderLogicProvider } from './useLogic';
import NavigationMobile from '../navigation-mobi';

const HeaderUI = () => {
    const [isOpenDrawer, setIsOpenDrawer] = useState(false);
    return (
        <HeaderLogicProvider>
            <header className="bg-white shadow-custom-1 fixed w-full top-0 z-50">
                {/* Header Top */}
                <div className="h-[70px] bg-[#A75D5D]">
                    <div className="mx-auto container">
                        <div className="flex justify-between items-center">
                            {/* Menu Mobile  */}
                            <div className="w-[50px] lg:hidden" onClick={() => setIsOpenDrawer(!isOpenDrawer)}>
                                <MenuIcon fill="#fff" width={30} height={30} />
                            </div>
                            <DrawerMenu onCloseDrawer={() => setIsOpenDrawer(false)} isOpenDrawer={isOpenDrawer} />
                            {/* Logo */}
                            <LogoUI />
                            {/* Search */}
                            <BoxSearch />
                            {/* Cart */}
                            <ShoppingCartUI />
                            <NavigationMobile />
                        </div>
                    </div>
                </div>
                {/* Header Bottom */}
                <div className="h-[50px]">
                    <HeaderBottom />
                </div>
            </header>
        </HeaderLogicProvider>
    );
};

export default memo(HeaderUI);
