import { PlusIcon } from '../../../../../core/components/icons/plus';

type TypeProps = {
    amount: number;
    price: number;
};

export const DescriptionCartItem = ({ amount, price }: TypeProps) => {
    return (
        <div className="flex items-center">
            <div className="text-sm text-[#FFC3A1] font-semibold">{amount}</div>
            <div className="transform rotate-45">
                <PlusIcon fill="#777" width={10} height={10} />
            </div>
            <div className="text-[#A75D5D] font-bold">
                {price.toLocaleString('vi-VN', {
                    style: 'currency',
                    currency: 'VND',
                })}
            </div>
        </div>
    );
};
