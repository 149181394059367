import { memo } from 'react';
import CategoryUI from './components/categories';
import CarouselBanner from './components/carousel-banner';
import './navigation.scss';
import News from './components/news';
import IsuresBottom from './components/isures';
const Navigation = () => {
    return (
        <section className="bg-[#ecf0f1]">
            <div className="mx-auto container">
                <div className="flex justify-between items-center py-4 gap-4">
                    <div className="w-[296px] hidden lg:block">
                        <CategoryUI />
                    </div>
                    <div className="flex-1 bg-slate-400 w-[calc(100%-592px)]">
                        <CarouselBanner />
                    </div>
                    <div className="w-[296px] hidden xl:flex h-[400px] ">
                        <News showBottom />
                    </div>
                </div>
                <IsuresBottom />
            </div>
        </section>
    );
};
export default memo(Navigation);
