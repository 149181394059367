import AxiosInstance from '~/core/util/axios';

import { ProductDiscountAttributes } from './dto';
import { ConfigEnv } from '~/configs';

export type getAllProductByCategoryQuery = {
    companyId: string;
    category: string;
    limit: number;
};

class HomeService {
    async getAllProductByCategory({ companyId, category, limit }: getAllProductByCategoryQuery) {
        try {
            const result = await AxiosInstance.get(`${ConfigEnv.PRODUCT_DOMAIN}/api-v1/product/category`, {
                params: { companyId, category, limit },
            });

            return result.data.products;
        } catch (error) {
            return {
                products: {
                    count: 0,
                    rows: [],
                },
            };
        }
    }

    async getProductBySlug(slug: string) {
        const result = await AxiosInstance.get(`${ConfigEnv.PRODUCT_DOMAIN}/api-v1/product/slug`, {
            params: { slug },
        });

        return result.data;
    }

    async getProductDiscountOfWeek(): Promise<{ products: ProductDiscountAttributes[] }> {
        try {
            const result = await AxiosInstance.get(`${ConfigEnv.PRODUCT_DOMAIN}/api-v1/product/product-discount`);

            return result.data;
        } catch (error) {
            return {
                products: [] as ProductDiscountAttributes[],
            };
        }
    }

    async getProductForHome(companyId?: string) {
        // const result = await AxiosInstance.get(`${ConfigEnv.PRODUCT_DOMAIN}/api-v1/product/home`, {
        //     params: {
        //         companyId,
        //     },
        // });
        const result = await AxiosInstance.get(
            `https://script.google.com/macros/s/AKfycbwysS6j3B_mVC8uYacTNpprenk0YJby-yhf1LM0XjhHV-sFMmeDo4Ju0DfIXhlW8p69Sw/exec`,
        );
        return result;
    }
}

const homeService = new HomeService();

export default homeService;
