import React from 'react';
import { GlobalIcon } from '../../../../../core/components/icons/global';
import { ShieldIcon } from '../../../../../core/components/icons/shield';
import { StarIcon } from '../../../../../core/components/icons/star';
import { TicketIcon } from '../../../../../core/components/icons/ticket';

function IsuresBottom() {
    return (
        <div className="pb-4">
            <div className="flex flex-wrap justify-between items-center bg-white">
                <div className="flex p-2 items-center w-full md:w-1/2 lg:w-1/4">
                    <div className="w-[50px] h-[50px] bg-[#A75D5D] rounded-md mr-4 flex items-center justify-center">
                        <GlobalIcon fill="#fff" width={40} height={40} />
                    </div>
                    <div className="flex flex-col">
                        <div className="uppercase text-sm"> Giao Hàng tiện lợi</div>
                        <div className="text-xs"> Giao hàng nhanh chóng, an toàn.</div>
                    </div>
                </div>
                <div className="flex p-2 items-center w-full md:w-1/2 lg:w-1/4">
                    <div className="w-[50px] h-[50px] bg-[#A75D5D] rounded-md mr-4 flex items-center justify-center">
                        <TicketIcon fill="#fff" width={40} height={40} />
                    </div>
                    <div className="flex flex-col">
                        <div className="uppercase text-sm"> Thanh Toán tiện lợi</div>
                        <div className="text-xs"> MOMO, ZaloPay, Chuyển khoản</div>
                    </div>
                </div>
                <div className="flex p-2 items-center w-full md:w-1/2 lg:w-1/4">
                    <div className="w-[50px] h-[50px] bg-[#A75D5D] rounded-md mr-4 flex items-center justify-center">
                        <ShieldIcon fill="#fff" width={50} height={50} />
                    </div>
                    <div className="flex flex-col">
                        <div className="uppercase text-sm"> Đổi Trả Dễ Dàng</div>
                        <div className="text-xs"> Đổi sản phẩm bị lỗi kỹ thuật trong 07 ngày.</div>
                    </div>
                </div>
                <div className="flex p-2 items-center w-full md:w-1/2 lg:w-1/4">
                    <div className="w-[50px] h-[50px] bg-[#A75D5D] rounded-md mr-4 flex items-center justify-center">
                        <StarIcon fill="#fff" width={40} height={40} />
                    </div>
                    <div className="flex flex-col">
                        <div className="uppercase text-sm">Tiết Kiệm Hơn</div>
                        <div className="text-xs">Luôn luôn có Khuyến mãi, Ưu đãi.</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default IsuresBottom;
