import { Badge, Card, Rate, Popover, Row, Col } from 'antd';
import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { HomeLogicContext } from '../../contextLogic';
import { convertVnd } from '~/core/util/common';
import Meta from 'antd/es/card/Meta';
import { TitleCartItem } from '~/layouts/components/header/components/shopping-cart/title-cart-item';
import ProductPrice from '~/pages/products/components/product-price';
import SkeletonProductItem from '../skeleton';

function Promotion() {
    const [value, setValue] = useState(3);

    const desc = ['terrible', 'bad', 'normal', 'good', 'wonderful'];

    const { promotion } = useContext(HomeLogicContext);
    return (
        <section className=" bg-slate-400">
            <div className="bg-white">
                <div className="mx-auto container py-6">
                    <div className="pb-2">
                        <div className="p-4 flex items-center justify-center bg-[#e74c3c] shadow-custom-1 border-b-[4px] border-[#e74c3c]">
                            <h4 className="text-2xl font-bold uppercase text-white text-center">Khuyến Mãi Hôm Nay</h4>
                        </div>
                    </div>
                    <div className="w-full flex items-center justify-between flex-row gap-2 mx-[1px] flex-wrap card-override-2">
                        {promotion.length > 0 ? (
                            <Row gutter={[16, 16]}>
                                {promotion?.map((product) => {
                                    return (
                                        <Col xs={24} sm={12} md={12} lg={6} xl={6} key={product.productId}>
                                            <Badge.Ribbon text={`-${product.product.discount}%`} color="red">
                                                <Card
                                                    hoverable
                                                    cover={
                                                        <Link to={`/product/${product.product.slug}`}>
                                                            <div className="w-full  h-[300px] lg:h-[200px] xl:h-[300px] border rounded-[8px]">
                                                                <img
                                                                    src={product.product.imageMain}
                                                                    alt=""
                                                                    className="w-full h-full"
                                                                />
                                                            </div>
                                                        </Link>
                                                    }
                                                >
                                                    <Meta
                                                        className="mb-2"
                                                        description={
                                                            <>
                                                                <TitleCartItem
                                                                    title={product.product.productName}
                                                                    href={`/product/${product.product.slug}`}
                                                                    isShowTrash={false}
                                                                />

                                                                <div className="flex items-center justify-between">
                                                                    <div>
                                                                        <Rate value={3} />
                                                                        <div className="p-1"></div>
                                                                        <ProductPrice
                                                                            price={product.product.price}
                                                                            discount={product.product.discount}
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <Popover
                                                                            placement="topRight"
                                                                            title={
                                                                                <div className="text-center">
                                                                                    Quà tặng
                                                                                </div>
                                                                            }
                                                                            content={
                                                                                <div className="flex items-center gap-2 flex-col">
                                                                                    {product.giftItems.map((gift) => {
                                                                                        return (
                                                                                            <div
                                                                                                className="flex justify-between items-center my-1 p-1 border rounded-md w-[250px]"
                                                                                                key={gift.productId}
                                                                                            >
                                                                                                <div className="line-clamp-2 w-[160px] ml-[10px]">
                                                                                                    {gift.productName}
                                                                                                </div>
                                                                                                <div className="w-[80px] h-[80px] object-cover">
                                                                                                    <img
                                                                                                        alt="example"
                                                                                                        src={
                                                                                                            gift.imageMain
                                                                                                        }
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        );
                                                                                    })}
                                                                                </div>
                                                                            }
                                                                            trigger="click"
                                                                        >
                                                                            <img
                                                                                src="https://firebasestorage.googleapis.com/v0/b/gasquynhl.appspot.com/o/resource-center%2Ffe-images%2Fvalentine-gift.png?alt=media&token=ee968e49-5f3e-4a15-8ea5-70ddb844dd23"
                                                                                alt=""
                                                                                className="w-[80px]"
                                                                            />
                                                                        </Popover>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        }
                                                    />
                                                </Card>
                                            </Badge.Ribbon>
                                        </Col>
                                    );
                                })}
                            </Row>
                        ) : (
                            <SkeletonProductItem />
                        )}
                    </div>
                    <div className="flex justify-center items-center my-2">
                        <Link
                            to={'/'}
                            className="transition-all duration-300 ease-in px-4 py-2 border-[3px] border-[#e74c3c] rounded-full text-sm text-[#e74c3c] font-bold hover:bg-[#e74c3c] hover:text-[white]"
                        >
                            Xem Thêm
                        </Link>
                    </div>
                    <div className="border-b-[4px] border-[#e74c3c]"></div>
                </div>
            </div>
        </section>
    );
}

export default Promotion;
