import AxiosInstance from '~/core/util/axios';
import { ConfigEnv } from '~/configs';
import { NewsAttributes } from './dto';
import { showMessageNotification } from '~/core/util/common';

class NewsService {
    constructor(readonly HTTPClient: typeof AxiosInstance) {}

    async getNewsBySlug({ slug, category }: { slug: string; category: string }) {
        const result = await AxiosInstance.get(
            `${ConfigEnv.PRODUCT_DOMAIN}/api-v1/news/slug/${slug}?category=${category}`,
        );
        return result?.data?.news;
    }

    async getAllNewsByCategory({ category, limit, pageIndex }: { limit: number; pageIndex: number; category: string }) {
        const result = await AxiosInstance.get(`${ConfigEnv.PRODUCT_DOMAIN}/api-v1/news/all`, {
            params: {
                limit,
                pageIndex,
                category,
            },
        });
        return result?.data?.news;
    }

    async getAllNews(params: any): Promise<{ news: { rows: NewsAttributes[]; count: number } }> {
        try {
            const result = await this.HTTPClient.get(`${ConfigEnv.PRODUCT_DOMAIN}/api-v1/news/all`, { params });
            return result.data;
        } catch (error: any) {
            showMessageNotification({
                type: 'error',
                title: `Lấy tin tức thất bại`,
                description: error?.message,
            });
            return {
                news: {
                    rows: [],
                    count: 0,
                },
            };
        }
    }

    async getCountNews() {
        const result = await AxiosInstance.get(`${ConfigEnv.PRODUCT_DOMAIN}/api-v1/news/count-news`);
        return result.data.news;
    }
}

const newsService = new NewsService(AxiosInstance);

export default newsService;
