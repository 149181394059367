import React, { useContext } from 'react';
import { HomeLogicContext } from '../../contextLogic';
import { Link } from 'react-router-dom';

function LatestNewsUI() {
    const { latestNews } = useContext(HomeLogicContext);
    return (
        <div className="w-full lg:w-2/3 pt-4">
            <Link to={`/news/${latestNews?.newsCategory}/${latestNews?.slug}`}>
                <div className="h-[200px] md:h-[400px] xl:h-[500px]">
                    <img src={latestNews?.bannerMain || '/'} alt="" className="w-full h-full" />
                </div>
                <h6 className="text-[18px] font-bold lg:hidden mt-2 truncate">{latestNews?.title}</h6>
            </Link>
        </div>
    );
}

export default LatestNewsUI;
