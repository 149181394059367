import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowIcon } from '../../../core/components/icons/arrow';

function FooterUI() {
    return (
        <footer>
            <section className="bg-white">
                <div className="mx-auto container py-10">
                    <div className="flex flex-wrap gap-4 lg:gap-2 items-start justify-between">
                        <div className="w-full md:w-[48%] lg:w-[24%]">
                            <h4 className="text-lg font-semibold uppercase pb-1 mb-9 border-b-[4px] border-[#A75D5D]">
                                Gas Quỳnh Lưu
                            </h4>
                            <div className="flex flex-col">
                                <Link
                                    to={'/'}
                                    className="text-sm hover:text-[#3498db]"
                                    aria-label={`Read more about the company Gas quynh luu`}
                                >
                                    Giới Thiệu
                                </Link>
                                <Link
                                    to={'/'}
                                    className="text-sm hover:text-[#3498db]"
                                    aria-label={`Read more about the system store of Gas quynh luu`}
                                >
                                    Hệ Thống Của Hàng
                                </Link>
                                <Link
                                    to={'/'}
                                    className="text-sm hover:text-[#3498db]"
                                    aria-label={`Suggestions for the system`}
                                >
                                    Liên Hệ Và Góp Ý
                                </Link>
                                <Link
                                    to={''}
                                    className="text-sm hover:text-[#3498db]"
                                    aria-label={`Read more about recruitment news`}
                                >
                                    Tuyển Dụng
                                </Link>
                                <Link
                                    to={''}
                                    className="text-sm hover:text-[#3498db]"
                                    aria-label={`Read more about Quynh Luu gas's partners`}
                                >
                                    Đối Tác Cung Ứng
                                </Link>
                            </div>
                        </div>
                        <div className="w-full md:w-[48%] lg:w-[24%]">
                            <h4 className="text-lg font-semibold uppercase pb-1 mb-9 border-b-[4px] border-[#A75D5D]">
                                Hỗ Trợ Khách Hàng
                            </h4>
                            <div className="flex flex-col ">
                                <Link
                                    to={''}
                                    className="text-sm hover:text-[#3498db] py-[2px] flex items-center gap-2"
                                    aria-label={`Read more about how to buy`}
                                >
                                    <div>Hướng Dẫn Mua Hàng</div>
                                    <div className="transform rotate-180">
                                        <ArrowIcon width={10} height={10} />
                                    </div>
                                </Link>
                                <Link
                                    to={''}
                                    className="text-sm hover:text-[#3498db] py-[2px] flex items-center gap-2"
                                    aria-label={`Read more about how to pay`}
                                >
                                    <div>Hướng Dẫn Thanh Toán</div>
                                    <div className="transform rotate-180">
                                        <ArrowIcon width={10} height={10} />
                                    </div>
                                </Link>
                                <Link
                                    to={''}
                                    className="text-sm hover:text-[#3498db] py-[2px] flex items-center gap-2"
                                    aria-label={`Read more about return instructions`}
                                >
                                    <div>Hướng Dẫn Đổi Trả</div>
                                    <div className="transform rotate-180">
                                        <ArrowIcon width={10} height={10} />
                                    </div>
                                </Link>
                                <Link
                                    to={''}
                                    className="text-sm hover:text-[#3498db] py-[2px] flex items-center gap-2"
                                    aria-label={`Read more about terms of use`}
                                >
                                    <div>Điều Kiện & Điều Khoản</div>
                                    <div className="transform rotate-180">
                                        <ArrowIcon width={10} height={10} />
                                    </div>
                                </Link>
                                <Link
                                    to={''}
                                    className="text-sm hover:text-[#3498db] py-[2px] flex items-center gap-2"
                                    aria-label={`Read more about the privacy policy`}
                                >
                                    <div>Chính Sách Bảo Mật</div>
                                    <div className="transform rotate-180">
                                        <ArrowIcon width={10} height={10} />
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="w-full md:w-[48%] lg:w-[24%]">
                            <h4 className="text-lg font-semibold uppercase pb-1 mb-9 border-b-[4px] border-[#A75D5D]">
                                Tổng Đài Hỗ Trợ
                            </h4>
                            <div className="flex flex-col  border-b-[1px]">
                                <div className="text-sm">
                                    Mua hàng:
                                    <Link
                                        to={'tel:0366361624'}
                                        className="hover:text-[#3498db] cursor-pointer font-bold text-base"
                                        aria-label={`Call the hotline to order`}
                                    >
                                        036.63.61.624
                                    </Link>
                                    (5:00 - 22:00)
                                </div>
                                <div className="text-sm">
                                    Kỹ thuật:
                                    <Link
                                        aria-label={`Call the hotline to order`}
                                        to={'tel:0366361624'}
                                        className="hover:text-[#3498db] cursor-pointer font-bold text-base"
                                    >
                                        036.63.61.624
                                    </Link>
                                    (7:30 - 22:00)
                                </div>
                                <div className="text-sm">
                                    Bảo hành:
                                    <Link
                                        aria-label={`Call the hotline to order`}
                                        to={'tel:0366361624'}
                                        className="hover:text-[#3498db] cursor-pointer font-bold text-base"
                                    >
                                        036.63.61.624
                                    </Link>
                                    (8:00 - 20:00)
                                </div>
                                <div className="text-sm">
                                    Khiếu nại:
                                    <Link
                                        aria-label={`Call the hotline to order`}
                                        to={'tel:0366361624'}
                                        className="hover:text-[#3498db] cursor-pointer font-bold text-base"
                                    >
                                        036.63.61.624
                                    </Link>
                                    (5:00 - 22:00)
                                </div>
                                <div className="w-1/3 py-2">
                                    <div className="h-[42px]">
                                        <img
                                            src="https://firebasestorage.googleapis.com/v0/b/gasquynhl.appspot.com/o/resource-center%2Ffe-images%2Fbct.png?alt=media&token=d72b8fa7-80da-4e5b-a9aa-43b1a4436011"
                                            alt=""
                                            className="w-full h-full"
                                            loading="lazy"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-full md:w-[48%] lg:w-[24%]">
                            <h4 className="text-lg font-semibold uppercase pb-1 mb-9 border-b-[4px] border-[#A75D5D]">
                                Kết Nối Với Quỳnh Lưu
                            </h4>
                            <div className="flex flex-col border-b-[1px] ">
                                <div className="text-sm">Kết nối với quỳnh lưu: </div>
                                <div className="flex items-center justify-between px-8 py-2">
                                    <Link to={''}>
                                        <div className="w-[40px] h-[40px]">
                                            <img
                                                src="https://firebasestorage.googleapis.com/v0/b/gasquynhl.appspot.com/o/resource-center%2Ffe-images%2Fzalo.png?alt=media&token=57cf0a9a-1579-4fc9-a678-ac2e62494310"
                                                alt=""
                                                className="w-full h-full"
                                                loading="lazy"
                                            />
                                        </div>
                                    </Link>
                                    <Link to={''}>
                                        <div className="w-[40px] h-[40px]">
                                            <img
                                                src="https://firebasestorage.googleapis.com/v0/b/gasquynhl.appspot.com/o/resource-center%2Ffe-images%2Fyoutube.png?alt=media&token=54e246bf-f129-4bc2-9dc9-46a6c1e31d95"
                                                alt=""
                                                className="w-full h-full"
                                                loading="lazy"
                                            />
                                        </div>
                                    </Link>
                                    <Link to={''}>
                                        <div className="w-[40px] h-[40px]">
                                            <img
                                                src="https://firebasestorage.googleapis.com/v0/b/gasquynhl.appspot.com/o/resource-center%2Ffe-images%2Ffacebook.png?alt=media&token=e240dba6-c831-4f35-b0a0-8ecb4063ffeb"
                                                alt=""
                                                className="w-full h-full"
                                                loading="lazy"
                                            />
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-[#A75D5D]">
                <div className="flex flex-col p-4 justify-center items-center  sm:mb-0 mb-20">
                    <p className="text-white text-xs text-center">
                        Copyright 2023. Công ty TNHH Quynh Luu Việt Nam. GPDKKD: 0362254887 do Sở KH & ĐT TP.Đà Nẵng cấp
                        ngày 20/2/2018. Thương hiệu Gas Quỳnh Lưu được bảo hộ trên toàn lãnh thổ Việt Nam.
                    </p>
                    <p className="text-white text-xs text-center">
                        Dịa chỉ: 144 Huỳnh Văn Nghệ, Phường Hòa Quý, Quận Ngũ Hành Sơn, TP.Đà Nẵng. Điện thoại: (036) 63
                        61 624. Email: cskh@gasquynhluu.com. Chịu trách nhiệm nội dung: Nguyễn Vấn.
                    </p>
                </div>
            </section>
        </footer>
    );
}

export default FooterUI;
