import { Link } from 'react-router-dom';
import { LogoIcon } from '~/core/components/icons/logo';

export const LogoUI = () => {
    return (
        <Link to={'/'}>
            <div className="w-[250px] h-[70px] lg:mr-[46px] transform scale-75 md:scale-90 lg:scale-100">
                <LogoIcon />
            </div>
        </Link>
    );
};
