import { Badge, Card, Col, Rate, Row, Skeleton } from 'antd';
import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { FireIcon } from '~/core/components/icons/fire';
import { HomeLogicContext } from '../../contextLogic';
import Meta from 'antd/es/card/Meta';
import { TitleCartItem } from '~/layouts/components/header/components/shopping-cart/title-cart-item';
import ProductPrice from '~/pages/products/components/product-price';
import SkeletonProductItem from '../skeleton';

function GasSafe() {
    const { gasSafe } = useContext(HomeLogicContext);

    return (
        <section className="">
            <div className="bg-[#ecf0f1] py-4">
                <div className="mx-auto container">
                    <div className="flex items-center h-[45px]">
                        <div className=" w-[45px] bg-[#FFC3A1] flex items-center justify-center h-full">
                            <FireIcon fill="#fff"></FireIcon>
                        </div>
                        <div className="uppercase flex items-center justify-center bg-[#A75D5D] px-4 h-full">
                            <h5 className="text-lg text-white font-bold">Gas An Toàn</h5>
                        </div>
                        <div className="flex justify-end items-center border-b-[4px] border-[#A75D5D] h-full flex-1">
                            <div className="items-center justify-center hidden md:flex">
                                <Link
                                    to={'/products?limit=20&categories=binh-gas-dan-dung'}
                                    className="hover:text-[#A75D5D] px-3"
                                >
                                    Bình Gas Dân Dụng{' '}
                                </Link>
                                <Link
                                    to={'/products?limit=20&categories=binh-gas-mini'}
                                    className="border-l-[1px] border-r-[1px] border-[#A75D5D] px-3 hover:text-[#A75D5D]"
                                >
                                    Bình Gas Mini
                                </Link>
                                <Link
                                    to={'/products?limit=20&categories=binh-gas-cong-nghe'}
                                    className="hover:text-[#A75D5D] pl-3"
                                >
                                    Bình Gas Công Nghiệp{' '}
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="w-full  mx-[1px] py-2 card-override-1">
                        {gasSafe.length > 0 ? (
                            <Row gutter={[16, 16]}>
                                {gasSafe?.map((product) => {
                                    return (
                                        <Col xs={12} sm={12} md={12} lg={6} xl={6} key={product.productId}>
                                            <Badge.Ribbon text={`-${product.discount}%`} color="red">
                                                <Card
                                                    hoverable
                                                    cover={
                                                        <Link to={`/product/${product.slug}`}>
                                                            <div className="w-full  h-[150px] md:h-[300px] lg:h-[200px] xl:h-[300px]  border rounded-[8px]">
                                                                <img
                                                                    src={product.imageMain}
                                                                    alt=""
                                                                    className="w-full h-full"
                                                                />
                                                            </div>
                                                        </Link>
                                                    }
                                                >
                                                    <Meta
                                                        className="mb-2"
                                                        description={
                                                            <>
                                                                <TitleCartItem
                                                                    title={product.productName}
                                                                    href={`/product/${product.slug}`}
                                                                    isShowTrash={false}
                                                                />

                                                                <div className="flex items-center justify-between">
                                                                    <div>
                                                                        <Rate value={3} />
                                                                        <div className="p-1"></div>
                                                                        <ProductPrice
                                                                            price={product.price}
                                                                            discount={product.discount}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </>
                                                        }
                                                    />
                                                </Card>
                                            </Badge.Ribbon>
                                        </Col>
                                    );
                                })}
                            </Row>
                        ) : (
                            <SkeletonProductItem />
                        )}
                    </div>
                    <div className="flex justify-center items-center my-4">
                        <Link
                            to={'/products?limit=20&categories=binh-gas'}
                            className="transition-all duration-300 ease-in px-4 py-2 border-[3px] border-[#A75D5D] rounded-full text-sm text-[#A75D5D] font-bold hover:bg-[#A75D5D] hover:text-[white]"
                        >
                            Xem Thêm
                        </Link>
                    </div>
                    <div className="w-full flex flex-col md:flex-row items-center gap-4">
                        <Link to={'/'} className="w-full">
                            <img
                                src="https://firebasestorage.googleapis.com/v0/b/gasquynhl.appspot.com/o/poster%2Fkhuyen-mai.jpg?alt=media&token=0e6158ee-de96-486c-ba36-ab288b3b703e&_gl=1*1ewwiz8*_ga*NzMyMzQ5OTI1LjE2OTA5MDMxMDI.*_ga_CW55HF8NVT*MTY5NjA4MDc0OC4xMi4xLjE2OTYwODA4MTEuNTkuMC4w"
                                alt=""
                                className="w-full object-contain"
                            />
                        </Link>
                        <Link to={'/'} className="w-full">
                            <img
                                src="https://firebasestorage.googleapis.com/v0/b/gasquynhl.appspot.com/o/poster%2Fnhan-qua.jpg?alt=media&token=70acac03-16d5-47e9-bd0c-0360b30b585b&_gl=1*w7xzmh*_ga*NzMyMzQ5OTI1LjE2OTA5MDMxMDI.*_ga_CW55HF8NVT*MTY5NjA4MDc0OC4xMi4xLjE2OTYwODA5MjguMzUuMC4w"
                                alt=""
                                className="w-full object-contain"
                            />
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default GasSafe;
