import { notification } from 'antd';
import dayjs from 'dayjs';
export const convertVnd = (money: number) => {
    if (!money) return '0đ';
    return money.toLocaleString('vi-VN', {
        style: 'currency',
        currency: 'VND',
    });
};

type NotificationArgs = {
    type: 'error' | 'success' | 'warning' | 'info';
    title: React.ReactNode;
    description: React.ReactNode;
};

const showMessageNotification = ({ type, title, description }: NotificationArgs) => {
    notification[type]({
        message: title,
        description,
    });
};

function formatDate(dateString: string, formatString: string) {
    const date = dayjs(dateString);

    const formattedDate = date.format(formatString);

    return formattedDate;
}

function scrollTop() {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
}

export { showMessageNotification, formatDate, scrollTop };
