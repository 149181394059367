import axios, { AxiosResponse } from 'axios';
import LocalStorage from './storage';
import { LOCAL_STORAGE_KEY } from '../components/constants';

const AxiosInstance = axios.create();
let isAlreadyFetchingAccessToken = false;
let subscribers: any = [];

function onAccessTokenFetched(access_token: any) {
    subscribers = subscribers.filter((callback: any) => callback(access_token));
}

function addSubscriber(callback: any) {
    subscribers.push(callback);
}

AxiosInstance.interceptors.response.use(
    (response) => {
        return response.data;
    },
    (error) => {
        const { config, response } = error;
        const originalRequest = config;

        if (response && response.status === 401) {
            const refreshToken = LocalStorage.get(LOCAL_STORAGE_KEY.REFRESH_TOKEN);
            const accessToken = LocalStorage.get(LOCAL_STORAGE_KEY.ACCESS_TOKEN);
            if (refreshToken && accessToken) {
                if (!isAlreadyFetchingAccessToken) {
                    isAlreadyFetchingAccessToken = true;

                    axios
                        .post(`http://localhost:9091/api-v1/auth/refresh/token`, {
                            tokenId: refreshToken,
                            accessToken,
                        })
                        .then((response: AxiosResponse) => {
                            isAlreadyFetchingAccessToken = false;
                            LocalStorage.set(LOCAL_STORAGE_KEY.ACCESS_TOKEN, response.data.data.token.accessToken);
                            LocalStorage.set(LOCAL_STORAGE_KEY.REFRESH_TOKEN, response.data.data.token.refreshTokenId);
                            onAccessTokenFetched(response.data.data.token.accessToken);
                        })
                        .catch((error) => {
                            localStorage.clear();
                            window.location.href = '/login';
                        });
                }

                const retryOriginalRequest = new Promise((resolve, reject) => {
                    addSubscriber(async (access_token: string) => {
                        try {
                            originalRequest.headers.Authorization = 'Bearer ' + access_token;
                            const { data } = await axios(originalRequest);
                            resolve(data);
                        } catch (error: any) {
                            reject(error.response.data);
                        }
                    });
                });
                return retryOriginalRequest;
            }
        }
        return Promise.reject(error.response.data);
    },
);

AxiosInstance.interceptors.request.use(async (config: any) => {
    let token = LocalStorage.get(LOCAL_STORAGE_KEY.ACCESS_TOKEN);
    if (typeof token !== 'undefined') {
        // config.headers.Authorization = `Bearer ${token}`;
        // config.headers['Access-Control-Allow-Origin'] = '*';
    }
    return config;
});

export default AxiosInstance;
