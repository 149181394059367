import { ButtonPrimary } from '../../../../../core/components/common/button';

export const FooterCart = ({ price = 0 }) => {
    return (
        <div className="flex  flex-col pt-3 gap-2 border-t-[1px]">
            <div className=" text-sm font-bold text-[#A75D5D] uppercase flex justify-center items-center border-b-[1px] pb-3">
                <p className="p-0 m-0 mr-2">Tổng phụ Thu: </p>
                {price.toLocaleString('vi-VN', {
                    style: 'currency',
                    currency: 'VND',
                })}
            </div>
            <ButtonPrimary className="p-2 rounded-full bg-[#A75D5D] text-lg font-bold text-white uppercase">
                Xem Tất Cả
            </ButtonPrimary>
            <ButtonPrimary className="p-2 rounded-full bg-[#FFC3A1] text-lg font-bold text-white uppercase">
                Thanh Toán
            </ButtonPrimary>
        </div>
    );
};
