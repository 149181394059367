import { Card, Col, Row, Skeleton } from 'antd';
import Meta from 'antd/es/card/Meta';
import React from 'react';

type SkeletonProps = {
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
    length?: number;
};
function SkeletonProductItem({ xs, sm, md, lg, xl, length }: SkeletonProps) {
    return (
        <Row gutter={[16, 16]}>
            {Array.from({ length: length || 8 }).map((item, i) => (
                <Col xs={xs || 24} sm={sm || 12} md={md || 12} lg={lg || 6} xl={xl || 6} key={i + '234523H'}>
                    <Card
                        hoverable
                        cover={
                            <Skeleton.Image
                                active={true}
                                className="w-[300px] lg:h-[200px] xl:h-[300px]"
                                style={{ width: '100%', height: '100%' }}
                            />
                        }
                    >
                        <Meta
                            className="mb-2"
                            description={
                                <>
                                    <Skeleton.Input active={true} size={'large'} />

                                    <div className="flex items-center justify-between mt-3">
                                        <div>
                                            <Skeleton.Input active={true} size={'small'} />
                                            <div className="p-1"></div>
                                            <div className="flex gap-16">
                                                <Skeleton.Input active={true} size={'large'} />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        />
                    </Card>
                </Col>
            ))}
        </Row>
    );
}

export default SkeletonProductItem;
