/**
 * REGEX
 */
export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]+$/;

/**
 * VARIABLE
 */

export enum AccessControl {
    OWNER = 'OWNER',
    COMPANY = 'COMPANY',
}

export const COMPANY_EVENTS = {
    USER_CREATED_COMPANY: 'USER_CREATED_COMPANY',
    READ_NOTIFICATION_CREATED_COMPANY: 'READ_NOTIFICATION_CREATED_COMPANY',
    UPDATED_NOTIFICATION_READ: 'UPDATED_NOTIFICATION_READ',
};

export const COMPANY_ROOMS = {
    MANAGER: 'MANAGER',
    OWNER: 'OWNER',
};

export const LOCAL_STORAGE_KEY = {
    ACCESS_TOKEN: 'ACCESS_TOKEN',
    REFRESH_TOKEN: 'REFRESH_TOKEN',
};

export const TYPE_NOTIFICATION = {
    NEW_COMPANY: 'NEW_COMPANY',
};

export const APP_ENV = {
    PRODUCTION: 'production',
    DEV: 'development',
    LOCAL: 'local',
};
