import React, { useEffect } from 'react';
import './style.scss';
import { Link } from 'react-router-dom';
function NavigationMobile() {
    useEffect(() => {
        const list = document.querySelectorAll('.list');
        list.forEach((item) =>
            item.addEventListener('click', () => {
                list.forEach((item) => item.classList.remove('active'));
                item.classList.add('active');
            }),
        );
    }, []);

    return (
        <div className="fixed bottom-0 right-0 w-full sm:hidden">
            <div className="navigation">
                <ul>
                    <li className="list">
                        <Link to={'/products?limit=20'}>
                            <span className="icon">
                                <svg
                                    fill="#000000"
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 52 52"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="m24 35.33a.81.81 0 0 1 .81.71v11.52a2.44 2.44 0 0 1 -2.32 2.44h-16.42a2.45 2.45 0 0 1 -2.44-2.28v-11.57a.81.81 0 0 1 .71-.81h19.66zm23.61 0a.82.82 0 0 1 .81.71v11.52a2.44 2.44 0 0 1 -2.33 2.44h-16.42a2.44 2.44 0 0 1 -2.43-2.28v-11.57a.81.81 0 0 1 .71-.81h19.61zm-29.92 3.37-.09.07-4.6 5.06-2.11-2a.62.62 0 0 0 -.79-.07l-.08.07-.87.78a.49.49 0 0 0 -.07.71l.07.08 3 2.83a1.25 1.25 0 0 0 .87.36 1.15 1.15 0 0 0 .87-.36l5.52-5.84a.63.63 0 0 0 .06-.72l-.06-.07-.87-.78a.61.61 0 0 0 -.85-.12zm23.61 0-.09.07-4.66 5.06-2.11-2a.61.61 0 0 0 -.78-.07l-.09.07-.87.78a.49.49 0 0 0 -.06.71l.06.08 3 2.83a1.25 1.25 0 0 0 .87.36 1.14 1.14 0 0 0 .87-.36l5.56-5.89a.65.65 0 0 0 0-.72v-.07l-.87-.78a.61.61 0 0 0 -.83-.07zm-18.76-11.52a2.36 2.36 0 0 1 2.27 2.28v2.61a.81.81 0 0 1 -.66.81h-21.39a.78.78 0 0 1 -.76-.7v-2.55a2.38 2.38 0 0 1 2.13-2.44h18.41zm25.18 0a2.36 2.36 0 0 1 2.28 2.28v2.61a.81.81 0 0 1 -.66.81h-21.4a.78.78 0 0 1 -.75-.71v-2.54a2.38 2.38 0 0 1 2.13-2.44h18.4zm-12-17a.81.81 0 0 1 .8.71v11.48a2.44 2.44 0 0 1 -2.28 2.44h-16.37a2.46 2.46 0 0 1 -2.44-2.29v-11.52a.81.81 0 0 1 .71-.8h19.62zm-6.27 3.37-.08.07-4.66 5.06-2.11-2a.61.61 0 0 0 -.78-.07l-.09.07-.87.78a.5.5 0 0 0 -.07.71l.07.08 3 2.82a1.22 1.22 0 0 0 .87.37 1.13 1.13 0 0 0 .87-.37l5.53-5.83a.65.65 0 0 0 .05-.72l-.05-.07-.87-.78a.62.62 0 0 0 -.77-.15zm6.31-11.55a2.44 2.44 0 0 1 2.43 2.28v2.61a.83.83 0 0 1 -.71.81h-22.86a.81.81 0 0 1 -.81-.7v-2.56a2.44 2.44 0 0 1 2.27-2.44z" />
                                </svg>
                            </span>
                            <span className="text">Product</span>
                        </Link>
                    </li>
                    <li className="list">
                        <Link to={'/news'}>
                            <span className="icon">
                                <svg
                                    fill="#000000"
                                    height="24px"
                                    width="24px"
                                    version="1.1"
                                    id="Layer_1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    viewBox="0 0 512 512"
                                    xmlSpace="preserve"
                                >
                                    <g>
                                        <g>
                                            <g>
                                                <path
                                                    d="M490.667,234.667h-64V35.371C426.667,14.629,412.038,0,391.296,0H37.589C16.826,0,0,16.826,0,37.589v436.821
				C0,495.174,16.826,512,37.589,512H448c35.355,0,64-28.645,64-64V256C512,244.218,502.449,234.667,490.667,234.667z
				 M42.667,42.667H384V256v192c0,0.958,0.026,1.912,0.072,2.863c0.284,6.453,1.526,12.653,3.582,18.471H42.667V42.667z
				 M469.333,448c0,11.791-9.542,21.333-21.333,21.333c-0.481,0-0.951-0.041-1.423-0.072c-9.603-0.862-18.976-10.235-19.838-19.838
				c-0.031-0.473-0.072-0.943-0.072-1.423V277.333h42.667V448z"
                                                />
                                                <path
                                                    d="M85.333,213.333h128c11.782,0,21.333-9.551,21.333-21.333V85.333c0-11.782-9.551-21.333-21.333-21.333h-128
				C73.551,64,64,73.551,64,85.333V192C64,203.782,73.551,213.333,85.333,213.333z M106.667,106.667H192v64h-85.333V106.667z"
                                                />
                                                <path
                                                    d="M341.333,256h-256C73.551,256,64,265.551,64,277.333c0,11.782,9.551,21.333,21.333,21.333h256
				c11.782,0,21.333-9.551,21.333-21.333C362.667,265.551,353.115,256,341.333,256z"
                                                />
                                                <path
                                                    d="M170.667,320H85.333C73.551,320,64,329.551,64,341.333c0,11.782,9.551,21.333,21.333,21.333h85.333
				c11.782,0,21.333-9.551,21.333-21.333C192,329.551,182.449,320,170.667,320z"
                                                />
                                                <path
                                                    d="M341.333,85.333h-64c-11.782,0-21.333,9.551-21.333,21.333c0,11.782,9.551,21.333,21.333,21.333h64
				c11.782,0,21.333-9.551,21.333-21.333C362.667,94.885,353.115,85.333,341.333,85.333z"
                                                />
                                                <path
                                                    d="M341.333,149.333h-64c-11.782,0-21.333,9.551-21.333,21.333c0,11.782,9.551,21.333,21.333,21.333h64
				c11.782,0,21.333-9.551,21.333-21.333C362.667,158.885,353.115,149.333,341.333,149.333z"
                                                />
                                                <path
                                                    d="M341.333,320H256c-11.782,0-21.333,9.551-21.333,21.333c0,11.782,9.551,21.333,21.333,21.333h85.333
				c11.782,0,21.333-9.551,21.333-21.333C362.667,329.551,353.115,320,341.333,320z"
                                                />
                                                <path
                                                    d="M170.667,384H85.333C73.551,384,64,393.551,64,405.333c0,11.782,9.551,21.333,21.333,21.333h85.333
				c11.782,0,21.333-9.551,21.333-21.333C192,393.551,182.449,384,170.667,384z"
                                                />
                                                <path
                                                    d="M341.333,384H256c-11.782,0-21.333,9.551-21.333,21.333c0,11.782,9.551,21.333,21.333,21.333h85.333
				c11.782,0,21.333-9.551,21.333-21.333C362.667,393.551,353.115,384,341.333,384z"
                                                />
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </span>
                            <span className="text">News</span>
                        </Link>
                    </li>
                    <li className="list active">
                        <Link to={'/'}>
                            <span className="icon">
                                <svg
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M5.77778 10.2222V18C5.77778 19.1046 6.67321 20 7.77778 20H12M5.77778 10.2222L11.2929 4.70711C11.6834 4.31658 12.3166 4.31658 12.7071 4.70711L17.5 9.5M5.77778 10.2222L4 12M18.2222 10.2222V18C18.2222 19.1046 17.3268 20 16.2222 20H12M18.2222 10.2222L20 12M18.2222 10.2222L17.5 9.5M17.5 9.5V6M12 20V15"
                                        stroke="#000000"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </span>
                            <span className="text">Home</span>
                        </Link>
                    </li>
                    <li className="list">
                        <Link to={'/'}>
                            <span className="icon">
                                <svg
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M21 5L19 12H7.37671M20 16H8L6 3H3M11 6L13 8L17 4M9 20C9 20.5523 8.55228 21 8 21C7.44772 21 7 20.5523 7 20C7 19.4477 7.44772 19 8 19C8.55228 19 9 19.4477 9 20ZM20 20C20 20.5523 19.5523 21 19 21C18.4477 21 18 20.5523 18 20C18 19.4477 18.4477 19 19 19C19.5523 19 20 19.4477 20 20Z"
                                        stroke="#000000"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </span>
                            <span className="text">Giỏ Hàng</span>
                        </Link>
                    </li>
                    <li className="list">
                        <Link to={'/price-gas-to-day'}>
                            <span className="icon">
                                <svg
                                    fill="#000000"
                                    version="1.1"
                                    id="Capa_1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 47.05 47.049"
                                    xmlSpace="preserve"
                                >
                                    <g>
                                        <g>
                                            <path
                                                d="M43.656,16.982c-0.729-2.255,0.268-5.41-1.098-7.287C41.179,7.8,37.859,7.78,35.964,6.4
			c-1.876-1.365-2.914-4.523-5.168-5.255c-2.174-0.706-4.86,1.216-7.271,1.216c-2.41,0-5.097-1.922-7.271-1.216
			C14,1.876,12.962,5.035,11.085,6.4C9.19,7.78,5.872,7.8,4.493,9.695c-1.365,1.877-0.369,5.032-1.1,7.287
			C2.686,19.156,0,21.113,0,23.525c0,2.41,2.686,4.367,3.393,6.542c0.73,2.254-0.267,5.41,1.1,7.287
			c1.379,1.895,4.698,1.915,6.593,3.294c1.876,1.365,2.914,4.523,5.168,5.257c2.175,0.705,4.86-1.217,7.271-1.217
			c2.411,0,5.097,1.922,7.271,1.217c2.254-0.732,3.292-3.892,5.168-5.256c1.896-1.379,5.214-1.4,6.595-3.295
			c1.364-1.876,0.367-5.032,1.098-7.286c0.707-2.175,3.394-4.133,3.394-6.543C47.049,21.113,44.363,19.157,43.656,16.982z
			 M23.525,40.102c-9.155,0-16.577-7.423-16.577-16.577c0-9.156,7.422-16.578,16.577-16.578c9.156,0,16.577,7.422,16.577,16.578
			C40.102,32.679,32.681,40.102,23.525,40.102z"
                                            />
                                            <path
                                                d="M23.525,9.062c-7.975,0-14.463,6.488-14.463,14.463s6.488,14.463,14.463,14.463S37.988,31.5,37.988,23.525
			S31.5,9.062,23.525,9.062z M23.525,35.626c-6.673,0-12.102-5.429-12.102-12.102c0-6.673,5.429-12.102,12.102-12.102
			c6.672,0,12.102,5.428,12.102,12.102C35.627,30.198,30.197,35.626,23.525,35.626z"
                                            />
                                            <path
                                                d="M24.859,21.932c-1.836-0.692-2.593-1.146-2.593-1.858c0-0.605,0.454-1.211,1.858-1.211c0.853,0,1.536,0.149,2.073,0.323
			c0.269,0.087,0.562,0.057,0.808-0.084c0.246-0.141,0.42-0.38,0.479-0.656l0.107-0.5c0.105-0.492-0.17-0.983-0.645-1.151
			c-0.502-0.179-1.176-0.312-2.422-0.352v-0.917c0-0.552-0.449-1-1-1c-0.552,0-1,0.448-1,1v1.067c-2,0.455-3.609,1.945-3.609,3.848
			c0,2.096,1.601,3.177,3.913,3.956c1.6,0.54,2.302,1.059,2.302,1.88c0,0.864-0.837,1.34-2.068,1.34c-0.935,0-1.81-0.201-2.558-0.48
			c-0.266-0.1-0.563-0.081-0.814,0.052c-0.252,0.132-0.437,0.364-0.507,0.641l-0.135,0.538c-0.128,0.509,0.158,1.029,0.657,1.193
			c0.909,0.299,2.093,0.518,2.821,0.564v1.402c0,0.552,0.448,1,1,1c0.552,0,1-0.448,1-1v-1.555c3-0.434,3.896-2.073,3.896-3.997
			C28.421,24.029,27.432,22.838,24.859,21.932z"
                                            />
                                        </g>
                                    </g>
                                </svg>
                            </span>
                            <span className="text">Phone</span>
                        </Link>
                    </li>
                    <div className="indicator"></div>
                </ul>
            </div>
        </div>
    );
}

export default NavigationMobile;
