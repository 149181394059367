type TypeProps = {
    src: string;
    width?: string;
    height?: string;
    className?: string;
};

export const AvatarCartItem = ({ src, width = '50px', height = '50px', className }: TypeProps) => {
    return (
        <div className={`w-[${width}] h-[${height}] ${className}`}>
            <img src={src} alt="" className="w-full h-full object-contain" />
        </div>
    );
};
