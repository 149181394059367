import React from 'react';
import { Link } from 'react-router-dom';
import './index.scss';
function NotFoundPage() {
    return (
        <main className="select-none">
            <div className="w-screen h-screen bg-white">
                <div className="flex items-center h-full">
                    <div className="w-[55%]  flex flex-col items-center justify-center bg-[#F8F8FA] error_page h-screen">
                        <h2 className="uppercase text-center text-[100px] text-[#3EA9FF] leading-[100px] font-bold drop-shadow-2xl">
                            404 ERROR
                        </h2>
                        <p className="text-3xl mt-10 p-0">Oops! Không tìm thấy trang</p>

                        <div>
                            <p className="text-md mt-4 p-0 text-center">
                                Rất tiếc, trang bạn yêu cầu không khả dụng. Xin vui lòng thử lại hoặc trở về trang chủ
                            </p>
                        </div>

                        <div className="mt-10">
                            <Link
                                to={'/'}
                                className="px-8 py-3 bg-[#DB277D] rounded-full text-white font-semibold hover:opacity-70 flex items-center gap-2"
                            >
                                <svg
                                    height="24"
                                    width="24"
                                    version="1.1"
                                    id="_x32_"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    viewBox="0 0 512 512"
                                    xmlSpace="preserve"
                                >
                                    <g>
                                        <polygon
                                            fill="#fff"
                                            points="434.162,293.382 434.162,493.862 308.321,493.862 308.321,368.583 203.682,368.583 203.682,493.862 
		77.841,493.862 77.841,293.382 256.002,153.862 	"
                                        />
                                        <polygon
                                            fill="#fff"
                                            points="0,242.682 256,38.93 512,242.682 482.21,285.764 256,105.722 29.79,285.764 	"
                                        />
                                        <polygon
                                            fill="#fff"
                                            points="439.853,18.138 439.853,148.538 376.573,98.138 376.573,18.138 	"
                                        />
                                    </g>
                                </svg>
                                <span> Quay lại trang chủ</span>
                            </Link>
                        </div>
                    </div>
                    <div className="w-[45%]">
                        <img
                            src="https://firebasestorage.googleapis.com/v0/b/gasquynhl.appspot.com/o/resource-center%2Ffe-images%2F404.gif?alt=media&token=b3db909e-02d5-4fc7-93e9-d979bb0a4a23"
                            alt=""
                            draggable={false}
                            className="w-full h-full object-contain"
                        />
                    </div>
                </div>
            </div>
        </main>
    );
}

export default NotFoundPage;
