import React from 'react';
import './style.scss';
function AppLoading() {
    return (
        <div className="w-screen h-screen flex items-center justify-center">
            <div className="loader"></div>
        </div>
    );
}

export default AppLoading;
