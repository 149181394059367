import React, { memo, useCallback } from 'react';
import type { MenuProps } from 'antd';
import { Menu } from 'antd';
import { useNavigate } from 'react-router-dom';

type TypeProps = {
    mode?: any;
    defaultOpenKeys?: string[];
    onCloseDrawer?: React.Dispatch<React.SetStateAction<boolean>>;
};
function CategoryUI({ mode, defaultOpenKeys, onCloseDrawer }: TypeProps) {
    type MenuItem = Required<MenuProps>['items'][number];
    const navigate = useNavigate();
    function getItem(
        label: React.ReactNode,
        key?: React.Key | null,
        icon?: React.ReactNode,
        children?: MenuItem[],
        type?: 'group',
    ): MenuItem {
        return {
            key,
            icon,
            children,
            label,
            type,
        } as MenuItem;
    }

    const items: MenuItem[] = [
        getItem('Bình Gas An Toàn', 'sub1', null, [
            getItem('Bình Gas Công Nghiệp', '/products?limit=20&categories=binh-gas-cong-nghiep'),
            getItem('Bình Gas Công Nghệ', '/products?limit=20&categories=binh-gas-cong-nghe'),
            getItem('Bình Gas Dân Dụng', '/products?limit=20&categories=binh-gas-dan-dung'),
            getItem('Bình Gas Mini', '/products?limit=20&categories=binh-gas-mini'),
        ]),
        getItem('Bếp Gas', 'sub2', null, [
            getItem('Bếp Gas Công Nghiệp', '/products?limit=20&categories=bep-gas-cong-nghe'),
            getItem('Bếp Gas Đôi', '/products?limit=20&categories=bep-gas-doi'),
            getItem('Bếp Gas Đơn', '/products?limit=20&categories=bep-gas-don'),
            getItem('Bếp Gas Âm', '/products?limit=20&categories=bep-gas-am'),
            getItem('Bếp Gas Mini', '/products?limit=20&categories=bep-gas-mini'),
            getItem('Bếp Gas Hồng Ngoại', '/products?limit=20&categories=bep-gas-hong-ngoai'),
        ]),
        getItem('Bếp Điện', 'sub3', null, [
            getItem('Bếp Điện Từ', '/products?limit=20&categories=bep-dien-tu'),
            getItem('Bếp Điện Hồng Ngoại', '/products?limit=20&categories=bep-dien-hong-ngoai'),
            getItem('Bếp Điện Từ Hồng Ngoại', '/products?limit=20&categories=bep-dien-tu-hong-ngoai'),
            getItem(
                'Bếp Điện Mini',
                null,
                null,
                [
                    getItem('Bếp Điện Từ Mini', '/products?limit=20&categories=bep-dien-tu-mini'),
                    getItem('Bếp Điện Hồng Ngoại Mini', '/products?limit=20&categories=bep-dien-hong-ngoai-mini'),
                ],
                'group',
            ),
        ]),
        getItem('Linh Kiện -Phụ Kiện', 'sub4', null, [
            getItem('Dây Dẫn Gas An Toàn', '/products?limit=20&categories=day-dan-gas-an-toan-linh-kien'),
            getItem('Van Điều Áp Gas', '/products?limit=20&categories=van-dieu-ap-gas-linh-kien'),
            getItem('Kiềng Bếp Gas', '/products?limit=20&categories=kieng-bep-linh-kien'),
            getItem('Nút Vặn Bếp Gas', '/products?limit=20&categories=nut-van-bep-linh-kien'),
            getItem('Máy Báo Rò Rỉ Gas', '/products?limit=20&categories=may-bao-ro-ri-gas-linh-kien'),
        ]),
        getItem('Máy Hút Mùi', '/products?limit=20&categories=may-hut-mui', null),
        getItem('Máy Lọc Nước', '/products?limit=20&categories=may-loc-nuoc', null),
        getItem('Bộ Bình Gas Chính Hãng', '/products?limit=20&categories=bo-binh-gas-chinh-hang', null),
        getItem('Bếp Cũ Đổi Bếp Mới', '/products?limit=20&categories=bep-cu-doi-bep-moi', null),
        getItem('Combo Bình Và Bếp Gas', '/products?limit=20&categories=combo-binh-va-bep-gas', null),
    ];

    const onClick: MenuProps['onClick'] = (e) => {
        console.log('click', e);
    };

    const handleSelectCategory = useCallback((info: any) => {
        navigate(info.key);
        onCloseDrawer && onCloseDrawer(false);
    }, []);

    return (
        <div className="shadow-custom-1">
            <Menu
                onClick={onClick}
                onSelect={(info) => handleSelectCategory(info)}
                mode={mode}
                items={items}
                defaultOpenKeys={defaultOpenKeys}
            />
        </div>
    );
}

export default memo(CategoryUI);
