export const TicketIcon = ({ fill = '#000', width = 20, height = 20 }) => {
    return (
        <svg
            fill={fill}
            width={width}
            height={height}
            viewBox="0 -3.5 39 39"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M39 19.438v5.562c0 1.104-0.896 2-2 2h-35c-1.104 0-2-0.896-2-2v-5.5c1.657 0 3-1.344 3-3 0-1.657-1.343-3-3-3v-5.5c0-1.104 0.896-2 2-2h35c1.104 0 2 0.896 2 2v5.438c-1.657 0-3 1.343-3 3 0 1.656 1.343 3 3 3zM34 11c0-1.104-0.896-2-2-2h-25c-1.104 0-2 0.896-2 2v11c0 1.104 0.896 2 2 2h25c1.104 0 2-0.896 2-2v-11zM32 23h-25c-0.553 0-1-0.448-1-1v-11c0-0.553 0.447-1 1-1h25c0.552 0 1 0.447 1 1v11c0 0.552-0.448 1-1 1zM12.47 15.538l-0.907-2.58-0.907 2.58-2.614 0.11 2.054 1.704-0.709 2.648 2.176-1.526 2.175 1.526-0.708-2.647 2.053-1.704-2.613-0.111zM20.47 15.538l-0.907-2.58-0.907 2.58-2.614 0.11 2.054 1.704-0.709 2.648 2.176-1.526 2.175 1.526-0.708-2.647 2.053-1.704-2.613-0.111zM28.47 15.538l-0.907-2.58-0.907 2.58-2.613 0.11 2.053 1.704-0.709 2.648 2.176-1.526 2.175 1.526-0.708-2.647 2.053-1.704-2.613-0.111z"></path>
        </svg>
    );
};
