export const CallIcon = ({ fill = '#000', width = 25, height = 25 }) => {
    return (
        <>
            <svg
                fill={fill}
                width={width}
                height={height}
                viewBox="0 0 24 24"
                id="call-in"
                data-name="Flat Color"
                xmlns="http://www.w3.org/2000/svg"
                className="icon flat-color"
            >
                <path
                    id="secondary"
                    d="M21,7H18.41l3.3-3.29a1,1,0,1,0-1.42-1.42L17,5.59V3a1,1,0,0,0-2,0V8a1,1,0,0,0,1,1h5a1,1,0,0,0,0-2Z"
                    style={{ fill }}
                ></path>
                <path
                    id="primary"
                    d="M20.19,13a10,10,0,0,1-3.43-.91,2,2,0,0,0-2.56.83l-.51.85a12.69,12.69,0,0,1-3.44-3.45l.86-.49a2,2,0,0,0,.83-2.56A10,10,0,0,1,11,3.81,2,2,0,0,0,9,2H5.13A3,3,0,0,0,2.86,3a3.13,3.13,0,0,0-.71,2.43A19,19,0,0,0,18.58,21.85a3,3,0,0,0,.42,0,3,3,0,0,0,2-.73,3,3,0,0,0,1-2.26V15A2,2,0,0,0,20.19,13Z"
                    style={{ fill }}
                ></path>
            </svg>
        </>
    );
};
