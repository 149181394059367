import React from 'react';
import { convertVnd } from '~/core/util/common';

type TypeProps = {
    discount: number;
    price: number;
};

const ProductPrice = ({ discount, price }: TypeProps) => {
    return (
        <div className="flex items-center">
            <div className="text-gray-700 mr-3 text-sm line-through lg:hidden xl:block">{convertVnd(price)}</div>
            <div className="text-[#A75D5D] font-bold text-base">{convertVnd(price - (price / 100) * discount)}</div>
        </div>
    );
};

export default ProductPrice;
