import { Link } from 'react-router-dom';
import { CallIcon } from '../../../../../core/components/icons/call';
import { SearchIcon } from '../../../../../core/components/icons/search';
import { StarIcon } from '../../../../../core/components/icons/star';

export const BoxSearch = () => {
    return (
        <div className="flex-1 hidden justify-between items-center gap-4 lg:flex">
            {/* Search Box */}
            <div className="bg-white p-1 lg:w-[336px] xl:w-[384px] rounded-full hidden flex-1 lg:flex">
                <input
                    type="text"
                    placeholder="Tên sản phẩm..."
                    className="bg-transparent flex-1 outline-none border-none px-2"
                />
                <button className="bg-[#A75D5D] rounded-full px-4 py-1">
                    <SearchIcon fill="#fff" />
                </button>
            </div>
            {/* Lable Box */}
            <div className="justify-around items-center flex-1  hidden lg:flex">
                <Link to={'/'}>
                    <div className="flex items-center cursor-pointer">
                        <StarIcon fill="#FAC2A1" />
                        <div className="ml-2">
                            <p className="p-0 m-0 font-bold text-[14px] text-[#FAC2A1] leading-4">Kinh Nghiệm Hay</p>
                            <p className="p-0 m-0 font-medium text-[10px] xl:text-[12px] text-[#ecf0f1] ">
                                & Tin khuyên mãi
                            </p>
                        </div>
                    </div>
                </Link>

                <Link to={'/'}>
                    <div className="flex items-center cursor-pointer">
                        <CallIcon fill="#FAC2A1" />
                        <div className="ml-2">
                            <p className="p-0 m-0 font-bold text-[14px] text-[#FAC2A1] leading-4">0366361624</p>
                            <p className="p-0 m-0 font-medium text-[10px] xl:text-[12px] text-[#ecf0f1] ">
                                Tổng đài gọi gas
                            </p>
                        </div>
                    </div>
                </Link>
            </div>
        </div>
    );
};
