import React, { memo } from 'react';
import { Drawer } from 'antd';
import CategoryUI from '../../../navigation/components/categories/';
import './drawer-menu.scss';
type TypeProps = {
    onCloseDrawer: () => void;
    isOpenDrawer: boolean;
};

function DrawerMenu({ onCloseDrawer, isOpenDrawer }: TypeProps) {
    return (
        <div className="">
            <Drawer placement="left" onClose={onCloseDrawer} open={isOpenDrawer}>
                <CategoryUI mode="inline" defaultOpenKeys={['sub1']} onCloseDrawer={onCloseDrawer} />
            </Drawer>
        </div>
    );
}

export default memo(DrawerMenu);
