import { lazy } from 'react';
import { Home } from '../pages/home';
const PriceGasOfDay = lazy(() => import('../pages/price-gas-date'));
const ProductDetail = lazy(() => import('../pages/product-detail'));
const RegisterCompany = lazy(() => import('../pages/register-company'));
const News = lazy(() => import('../pages/news'));
const Products = lazy(() => import('../pages/products'));
const publicRoutes: Array<{
    path: string;
    exact?: boolean;
    component: any;
    isShowNavigation?: boolean;
    layout?: string | null;
}> = [
    { path: '/', component: Home },
    { path: '/products', component: Products, isShowNavigation: false, exact: true },
    { path: '/product/:slug', component: ProductDetail, isShowNavigation: false },
    { path: '/register/company', component: RegisterCompany, layout: null },
    { path: '/news/:category', component: News, isShowNavigation: false },
    { path: '/news', component: News, isShowNavigation: false },
    { path: '/news/:category/:slug', component: News, isShowNavigation: false },
    { path: '/price-gas-to-day', component: PriceGasOfDay, isShowNavigation: false },
];

function generateSitemap() {
    const routes = [...publicRoutes];
    const baseUrl = 'https://gasquynhluu.info.vn';

    const sitemapXml = `<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9" xmlns:news="http://www.google.com/schemas/sitemap-news/0.9" xmlns:xhtml="http://www.w3.org/1999/xhtml" xmlns:mobile="http://www.google.com/schemas/sitemap-mobile/1.0" xmlns:image="http://www.google.com/schemas/sitemap-image/1.1" xmlns:video="http://www.google.com/schemas/sitemap-video/1.1">
        ${routes
            .map((route) => {
                const url = new URL(route.path, baseUrl);
                return `
              <url>
                <loc>${url.href}</loc>
                <lastmod>${new Date()}</lastmod>
                <changefreq>daily</changefreq>
                <priority>0.7</priority>
              </url>
            `;
            })
            .join('\n')}
      </urlset>`;

    return sitemapXml;
}

const sitemap = generateSitemap();

export { publicRoutes };
