import React, { memo, useEffect, useState, useContext } from 'react';
import { List } from 'antd';
import { Link } from 'react-router-dom';
import './news.scss';
import { ConfigEnv } from '~/configs';
import newsService from '~/pages/news/service';
import { NewsAttributes } from '~/pages/news/dto';
import { formatDate } from '~/core/util/common';
import { HomeLogicContext } from '~/pages/home/contextLogic';

function News({
    showBottom = false,
    isShadow = true,
    isBorder = true,
    textSize = '14px',
    position = 'NAVI',
    classMore = '',
}) {
    const { setLatestNews } = useContext(HomeLogicContext);
    const [news, setNews] = useState<NewsAttributes[]>([]);
    const [newsNavi, setNewsNavi] = useState<NewsAttributes[]>([]);

    useEffect(() => {
        const getAllNews = async () => {
            const {
                news: { rows },
            } = await newsService.getAllNews({});
            setLatestNews && setLatestNews(rows[0]);
            setNewsNavi(rows.slice(1, 4));
            setNews(rows.slice(4));
        };
        getAllNews();
    }, []);

    return (
        <div className={`flex items-start justify-between flex-col news ${!isBorder ? 'no-border' : ''}`}>
            <div className={`bg-white w-full mb-2 ${isShadow ? 'shadow-custom-1' : ''} ${classMore}`}>
                <List
                    size="large"
                    bordered
                    dataSource={position === 'NAVI' ? newsNavi : news}
                    renderItem={(item) => (
                        <List.Item>
                            <Link to={`/news/${item.newsCategory}/${item.slug}`} className="hover:text-[#A75D5D]">
                                <div className="flex gap-2">
                                    {item?.bannerMain && position !== 'NAVI' && (
                                        <img src={item.bannerMain} alt="" className="w-[100px] h-[70px] object-cover" />
                                    )}
                                    <div>
                                        <p className={`p-0 m-0 text-[${textSize}] font-medium`}>{item.title}</p>
                                        {position !== 'NAVI' && (
                                            <div className="text-sm mt-1">
                                                Cập nhật: {formatDate(item.updatedAt, 'DD/MM/YYYY')}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Link>
                        </List.Item>
                    )}
                />
            </div>
            {showBottom && (
                <div className="w-full flex flex-col items-center gap-1 mt-[20px]">
                    <div className="b animate-bounce mx-auto h-16 w-64 flex justify-center items-center">
                        <div className="i h-16 w-64 bg-gradient-to-br from-[#A75D5D90] to-[#A75D5D] items-center rounded-xl shadow-2xl  cursor-pointer absolute overflow-hidden transform hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out"></div>
                        <Link
                            to={'/register/company'}
                            className="text-center text-white font-semibold z-10 flex justify-content items-center"
                        >
                            <span className="">
                                <svg
                                    className="w-5 h-5 right-1.5 relative"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </span>
                            Đăng ký quản lý
                        </Link>
                    </div>
                    <div className="b relative mx-auto h-16 w-64 flex justify-center items-center">
                        <div className="i h-16 w-64 bg-[#FFC3A180] items-center rounded-xl shadow-2xl cursor-pointer absolute overflow-hidden transform hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out"></div>
                        <Link
                            to={`${ConfigEnv.ADMIN_DOMAIN}/login`}
                            target="_blank"
                            className="text-center text-white font-semibold z-10"
                        >
                            Trang quản trị
                        </Link>
                        <span className="absolute flex h-6 w-6 top-0 right-0 transform translate-x-2.5 -translate-y-2.5">
                            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-[#FFC3A190] opacity-75"></span>
                            <span className="absolute inline-flex rounded-full h-6 w-6 bg-[#FFC3A1]"></span>
                        </span>
                    </div>
                </div>
            )}
        </div>
    );
}

export default memo(News);
