import { Outlet } from 'react-router-dom';
import HeaderUI from '../components/header';
import Navigation from '../components/navigation';
import FooterUI from '../components/footer';
import { Suspense } from 'react';
import AppLoading from '~/core/components/common/app-loading';

const DefaultLayout = ({ isShowNavigation = true }) => {
    return (
        <>
            <HeaderUI />

            <div className="mt-[120px]">
                {isShowNavigation && <Navigation />}
                <Suspense fallback={<AppLoading />}>
                    <Outlet></Outlet>
                </Suspense>
            </div>
            <FooterUI />
        </>
    );
};

export default DefaultLayout;
