import React, { useContext } from 'react';
import { SearchIcon } from '../../../../../core/components/icons/search';
import { formatDate } from '~/core/util/common';
import { Link } from 'react-router-dom';
import { HeaderLogicContext } from '../../useLogic';

function HeaderBottom() {
    const { priceGas } = useContext(HeaderLogicContext);
    return (
        <div className="mx-auto container h-full flex items-center">
            <div className=" lg:gap-4 items-center h-full hidden lg:flex">
                <div className="flex items-center w-[296px]">
                    <span className="relative flex h-3 w-3 mr-3">
                        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                        <span className="relative inline-flex rounded-full h-3 w-3 bg-sky-500"></span>
                    </span>
                    <div className="truncate">
                        <Link to="/products?limit=20">Sản phẩm mới cập nhật</Link>
                    </div>
                </div>
                <div className="flex items-center lg:w-[292px] xl:w-[412px] 2xl:w-[500px]">
                    <span className="relative flex h-3 w-3 mr-3">
                        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                        <span className="relative inline-flex rounded-full h-3 w-3 bg-sky-500"></span>
                    </span>
                    <div className="truncate">
                        <Link
                            to={`/news/${priceGas?.forecastPriceOfLatestMonth?.newsCategory}/${priceGas?.forecastPriceOfLatestMonth?.slug}`}
                        >
                            {priceGas?.forecastPriceOfLatestMonth?.title}
                        </Link>
                    </div>
                </div>
                <div className="flex items-center flex-1 lg:w-[378px] xl:w-[476px] 2xl:w-[512px]">
                    <span className="relative flex h-3 w-3 mr-3">
                        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                        <span className="relative inline-flex rounded-full h-3 w-3 bg-sky-500"></span>
                    </span>
                    <div className="truncate">
                        <Link to="/price-gas-to-day">{priceGas?.priceOfDate?.title}</Link>
                    </div>
                </div>
            </div>
            <div className="bg-white p-1 lg:w-[336px] xl:w-[384px] rounded-md flex-1 flex lg:hidden border-[2px] border-[#A75D5D]">
                <input
                    type="text"
                    placeholder="Tên sản phẩm..."
                    className="bg-transparent flex-1 outline-none border-none px-2"
                />
                <button className="bg-[#A75D5D] rounded-full px-4 py-1">
                    <SearchIcon fill="#fff" />
                </button>
            </div>
        </div>
    );
}

export default HeaderBottom;
