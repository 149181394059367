import AxiosInstance from '~/core/util/axios';
import { ConfigEnv } from '~/configs';

class HeaderService {
    static async getPriceGasOfMonth() {
        const result = await AxiosInstance.get(`${ConfigEnv.PRODUCT_DOMAIN}/api-v1/news/price-gas-of-month`);
        return result.data;
    }
}

export default HeaderService;
