import React, { useState } from 'react';
import './home.scss';
import { Rate } from 'antd';
import { Link } from 'react-router-dom';
import { FireIcon } from '../../core/components/icons/fire';
import News from '../../layouts/components/navigation/components/news';
import { ButtonPrimary } from '../../core/components/common/button';
import { ArrowIcon } from '../../core/components/icons/arrow';
import { HomeLogicProvider } from './contextLogic';
import GasSafe from './components/gas-safe';
import GasStove from './components/gas-stove';
import Promotion from './components/promotion';
import ElectricStove from './components/electric-stove';
import Accessory from './components/accessory';
import LatestNewsUI from './components/latest-news';
import TitleLatestNews from './components/latest-news/title';
export const Home = () => {
    return (
        <HomeLogicProvider>
            <main>
                {/* Promotion */}
                <Promotion />
                {/* Gas Safe */}
                <GasSafe />
                {/* Gas Stove*/}
                <GasStove />
                {/* Electromagnetic stove */}
                <ElectricStove />
                {/* Accessory*/}
                <Accessory />
                {/* News */}
                <section className="">
                    <div className="bg-[#ecf0f1] py-4">
                        <div className="mx-auto container ">
                            <div className="bg-white p-3">
                                <div className="flex items-center h-[45px]">
                                    <div className=" w-[45px] bg-[#FFC3A1] flex items-center justify-center h-full">
                                        <FireIcon fill="#fff"></FireIcon>
                                    </div>
                                    <div className="uppercase flex items-center justify-center bg-[#A75D5D] px-4 h-full">
                                        <h5 className="text-lg text-white font-bold">Tin Tức - Mẹo Hay</h5>
                                    </div>
                                    <div className="flex justify-end items-center border-b-[4px] border-[#A75D5D] h-full flex-1 "></div>
                                </div>
                                <div className="flex gap-2 lg:gap-4 flex-col lg:flex-row">
                                    <LatestNewsUI />
                                    <div className="w-full lg:w-1/3 lg:pt-4">
                                        <News
                                            isShadow={false}
                                            isBorder={false}
                                            textSize="16px"
                                            position="NEWS"
                                            classMore="h-[400px] xl:h-[500px] overflow-y-auto text-[18px] mb-0 home"
                                        />
                                        <div className="flex items-center justify-center lg:justify-end lg:hidden">
                                            <Link to={'/news'} className="mt-2">
                                                <div className="px-[6px] py-1 bg-[#A75D5D] text-white flex gap-1 items-center ">
                                                    <div className="text-sm">Xem tất cả bài viết</div>
                                                    <div className="transform -rotate-90 lg:rotate-180">
                                                        <ArrowIcon fill="#fff" />
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className=" justify-between items-center mt-2 hidden lg:flex">
                                    <TitleLatestNews className="" />
                                    <div className="flex items-center justify-center lg:justify-end">
                                        <Link to={'/news'} className="">
                                            <div className="px-[6px] py-1 bg-[#A75D5D] text-white flex gap-1 items-center ">
                                                <div className="text-sm">Xem tất cả bài viết</div>
                                                <div className="transform -rotate-90 lg:rotate-180">
                                                    <ArrowIcon fill="#fff" />
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </HomeLogicProvider>
    );
};
