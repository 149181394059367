import { Link } from 'react-router-dom';

import { TrashIcon } from '../../../../../core/components/icons/trash';

type TypeProps = {
    title: string;
    href: string;
    isShowTrash?: boolean;
    titleClass?: string;
};

export const TitleCartItem = ({ title, href, isShowTrash = true, titleClass }: TypeProps) => {
    return (
        <div className="flex justify-between">
            <Link to={href} className="hover:text-[#A75D5D]">
                <div
                    className={`lg:text-base text-md font-medium line-clamp-2 min-h-[40px] uppercase text-gray-700 hover:text-[#A75D5D] ${titleClass} `}
                >
                    {title}
                </div>
            </Link>
            {isShowTrash && (
                <div className="cursor-pointer">
                    <TrashIcon fill="#e74c3c" />
                </div>
            )}
        </div>
    );
};
