import { List, Popover } from 'antd';
import { ShoppingIcon } from '../../../../../core/components/icons/shopping';
import { FooterCart } from './footer-cart';
import { AvatarCartItem } from './avatar-cart-item';
import { TitleCartItem } from './title-cart-item';
import { DescriptionCartItem } from './description-cart-item';
import { memo } from 'react';

const ShoppingCartUI = () => {
    const data = [
        {
            title: 'Bếp gas dương kính SUNHOUSE MAMA MMB0780L 1',
            src: 'https://sunhouse.com.vn/pic/product/images/bep-gas-sunhouse-mama-mmb0780l_002.jpg',
            price: 6467000,
            amount: 2,
            href: '/',
        },
        {
            title: 'Bếp gas dương kính SUNHOUSE MAMA MMB0780L 2',
            src: 'https://sunhouse.com.vn/pic/product/images/bep-gas-sunhouse-mama-mmb0780l_002.jpg',
            price: 6467000,
            amount: 2,
            href: '/',
        },
        {
            title: 'Bếp gas dương kính SUNHOUSE MAMA MMB0780L 3',
            src: 'https://sunhouse.com.vn/pic/product/images/bep-gas-sunhouse-mama-mmb0780l_002.jpg',
            price: 6467000,
            amount: 2,
            href: '/',
        },
        {
            title: 'Bếp gas dương kính SUNHOUSE MAMA MMB0780L 4',
            src: 'https://sunhouse.com.vn/pic/product/images/bep-gas-sunhouse-mama-mmb0780l_002.jpg',
            price: 6467000,
            amount: 2,
            href: '/',
        },
        {
            title: 'Bếp gas dương kính SUNHOUSE MAMA MMB0780L 1',
            src: 'https://sunhouse.com.vn/pic/product/images/bep-gas-sunhouse-mama-mmb0780l_002.jpg',
            price: 6467000,
            amount: 2,
            href: '/',
        },
        {
            title: 'Bếp gas dương kính SUNHOUSE MAMA MMB0780L 2',
            src: 'https://sunhouse.com.vn/pic/product/images/bep-gas-sunhouse-mama-mmb0780l_002.jpg',
            price: 6467000,
            amount: 2,
            href: '/',
        },
        {
            title: 'Bếp gas dương kính SUNHOUSE MAMA MMB0780L 3',
            src: 'https://sunhouse.com.vn/pic/product/images/bep-gas-sunhouse-mama-mmb0780l_002.jpg',
            price: 6467000,
            amount: 2,
            href: '/',
        },
        {
            title: 'Bếp gas dương kính SUNHOUSE MAMA MMB0780L 4',
            src: 'https://sunhouse.com.vn/pic/product/images/bep-gas-sunhouse-mama-mmb0780l_002.jpg',
            price: 6467000,
            amount: 2,
            href: '/',
        },
    ];
    const content = (
        <div className="w-[300px]">
            <div className="max-h-[400px] overflow-y-auto">
                <List
                    className="list-item-cart"
                    itemLayout="horizontal"
                    dataSource={data}
                    renderItem={(item, index) => (
                        <List.Item>
                            <List.Item.Meta
                                avatar={<AvatarCartItem src={item.src} />}
                                title={<TitleCartItem title={item.title} href={item.href} />}
                                description={<DescriptionCartItem price={item.price} amount={item.amount} />}
                            />
                        </List.Item>
                    )}
                />
            </div>
            <FooterCart price={65909000} />
        </div>
    );
    return (
        <div className="flex justify-end pr-2 w-[50px] lg:w-max">
            <Popover content={content} trigger="click" placement="bottomRight">
                <button className="relative outline-none border-none rounded-full flex items-center bg-[#FAC2A1] text-[var(--main-color)] p-2 lg:px-[10px] lg:py-[6px]">
                    <div className="text-xs uppercase font-bold mr-1 hidden lg:block">Giỏ Hàng</div>
                    <div className="lg:hidden xl:block">
                        <ShoppingIcon fill="#a75d5d" width={20} height={20} />
                    </div>
                    <div className="absolute top-[-8px] right-[-6px] rounded-full w-[20px] h-[20px] bg-[#e74c3c] p-1 text-[10px] text-white">
                        10
                    </div>
                </button>
            </Popover>
        </div>
    );
};

export default memo(ShoppingCartUI);
